import React, { useEffect, useMemo, useState } from "react";
import parse from "autosuggest-highlight/parse";
import debounce from "lodash/debounce";

import {
  TextField,
  Grid,
  Typography,
  Box,
  InputProps as MuiInputProps,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { HomeIcon, OfficeIcon, AddressIcon } from "../../design-system/icons";
import MoovsAvatar from "../../design-system/components/MoovsAvatar";
import { useAnalytics } from "globals/hooks";

const autocompleteService: any = { current: null };

type PlaceType = {
  structured_formatting: {
    secondary_text: string;
    main_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
};

type suggestedAddressInfoType = {
  firstName: string;
  lastName: string;
  address: string;
  mode: string;
};

type LocationAutoCompleteProps = {
  id?: string;
  label?: string;
  onChange: (value: any, event?: any) => void;
  error?: boolean;
  helperText?: string;
  value: any;
  name?: string;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  styles?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  sxStyles?: any;
  suggestedAddressInfo?: suggestedAddressInfoType[];
  TextFieldInputProps?: MuiInputProps;
  disableClearable?: boolean;
};

export default function LocationAutoComplete(
  inputProps: LocationAutoCompleteProps
) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<
    PlaceType[] | suggestedAddressInfoType[]
  >([]);
  const [internalError, setInternalError] = useState(false);
  const {
    id,
    label,
    onChange,
    error,
    value,
    inputRef,
    disabled,
    helperText,
    name,
    suggestedAddressInfo,
    TextFieldInputProps,
    disableClearable,
  } = inputProps;

  // hooks
  const { track } = useAnalytics();

  // derived state
  const internalHelperText = internalError
    ? "Please select an address from dropdown list"
    : "";

  // event handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChange({ description: "" });
  };

  const handleOnBlur = () => {
    setInternalError(!value && !!inputValue);
  };

  const handleUpdateField = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: any,
    reason: string
  ) => {
    if (reason === "selectOption") {
      setInternalError(false);
    }

    if (reason === "clear") {
      onChange(
        {
          description: "",
        },
        event
      );
      setInputValue("");
      return;
    }

    if (value.address) {
      onChange({
        description: value.address,
      });
      return;
    }

    if (onChange && value) {
      onChange(value, event);
    }

    if (onChange && !value) {
      value = {
        description: "",
      };
      onChange(value, event);
    }
  };

  const fetch = useMemo(
    () =>
      debounce((input: any, callback: any) => {
        (autocompleteService.current as any).getPlacePredictions(
          input,
          callback
        );
      }, 350),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (inputValue === "" && suggestedAddressInfo?.length > 0) {
      setOptions(suggestedAddressInfo);
      return undefined;
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      track("google_placeAutocomplete");
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch, suggestedAddressInfo, track]);

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      disabled={disabled}
      onFocus={(e) => e.stopPropagation()}
      onChange={handleUpdateField}
      id={id}
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete={false}
      includeInputInList
      freeSolo
      placeholder={inputProps.placeholder}
      value={value || inputValue}
      disableClearable={disableClearable}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        return (
          <TextField
            inputRef={inputRef}
            {...restParams}
            error={!!error || internalError}
            helperText={helperText || internalHelperText}
            sx={{
              ...inputProps.sxStyles,
            }}
            InputProps={{
              ...InputProps,
              ...TextFieldInputProps,
            }}
            inputProps={{
              ...restParams.inputProps,
              autoComplete: "new-password",
            }}
            label={label}
            variant="outlined"
            name={name}
            fullWidth={inputProps.fullWidth}
            required={inputProps.required}
            onChange={handleChange}
            onBlur={handleOnBlur}
          />
        );
      }}
      renderOption={(props, option) => {
        // preferred address from contacts
        if (option.address) {
          const { address, firstName, lastName, mode } = option;
          return (
            <li {...props}>
              <Box
                display="flex"
                flex="1"
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  mr={1}
                  display="flex"
                  alignItems="center"
                  flex="1"
                  flexShrink={0}
                >
                  <Box display="flex" alignItems="center" marginRight={2}>
                    {mode === "homeAddress" ? (
                      <HomeIcon size="small" />
                    ) : (
                      <OfficeIcon />
                    )}
                  </Box>
                  <Typography variant="body2">{address}</Typography>
                </Box>
                <Box display="flex" flexShrink={0}>
                  <MoovsAvatar
                    size="small"
                    placeholder={[firstName, lastName]}
                  />
                </Box>
              </Box>
            </li>
          );
        }

        // google address
        if (option.description) {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <AddressIcon />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
      }}
    />
  );
}
